// overwrite bootstrap reboot for reveal
[hidden] {
  display: inherit !important;
}
.container, .container-sm, .container-md, .container-lg, .container-xl{
  max-width: 90vw;
  padding: 0;
}
// align content inside row middle
.row{
  align-items: center;
  justify-content: center;
}
.row.valign-middle{
  align-items: center;
}
.row.valign-top{
  align-items: start;
}
.row.valign-bottom{
  align-items: end;
}

h4, .h4{
  font-size: 1.35rem
}

.reveal.fade {
  //opacity: 1;
}

// END overwrite bootstrap reboot


  /********

    overwrite reveal theme
  
  *********/
  .reveal.center .slides section:not(.stack){
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 5vh 0;
  }

  /* presentation options */
  // whole layout
  .reveal.vlayout-top .slides section:not(.stack){
    align-items: start;
  }
  .reveal.vlayout-bottom .slides section:not(.stack){
    align-items: end;
  }
  // rows
  .reveal.valign-top .slides section:not(.stack) .row{
    align-items: start;
  }
  .reveal.valign-bottom .slides section:not(.stack) .row{
    align-items: end;
  }
  /* slide options */
  // whole layout
  .reveal .slides section.vlayout-top:not(.stack){
    align-items: start;
  }
  .reveal .slides section.vlayout-bottom:not(.stack){
    align-items: end;
  }
  // rows
  .reveal .slides section.valign-top:not(.stack) .row{
    align-items: start;
  }
  .reveal .slides section.valign-bottom:not(.stack) .row{
    align-items: end;
  }

  .reveal-viewport {
    background-color: inherit;
  }
  
  .reveal {
    
    .slides {
      text-align: inherit;
      font-family: inherit;
    }

    p {
      margin: 0 0 1rem;

      -webkit-font-smoothing: antialised;
      -webkit-font-smoothing: subpixel-antialiased;
      backface-visibility: hidden;
      transform: translateZ(0);          
    }
    img, video, iframe{
      max-width: 100%;
      max-height: 100%;
      width: 100%;

      &.portrait{
        width: 50%;
        @include media-breakpoint-up (md) {
          width: 100%;
        }         
      }        
    }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
    }

    /////// VERTICATOR
    ul.verticator,
    .progress span,
    .controls button{
      color:#aaa !important;
      --bullet-maincolor: #aaa !important;
    }
    &.has-light-background{
        ul.verticator,
        .progress span,
        .controls button{
            color:#222 !important;
            --bullet-maincolor: #222 !important;
        }
    }
    &.has-dark-background{
        ul.verticator,
        .progress span,
        .controls button{
            color:#fff !important;
            --bullet-maincolor: #fff !important;
        }
    }
    /////// END VERTICATOR
  }
  /* .v-centered{
    top: 50%;
    transform: translateY(-50%);
  } */
 /*  .box{
     p{
      @include media-breakpoint-up (sm) {
        max-height: 50vh;
      }
      @include media-breakpoint-up (xl) {
        max-height: 90vh;
      }
      max-height: 35vh;
      line-height: normal;
    } 
  } */
  .text-box{
    p, h1, h2, h3, h4, h5, h6{
      background-color: #11111188;
      padding: 0.75rem;
      color: $c-white;
    }
  }
  /********

    VIDEO
  
  *********/
  #audBtn{
    width: 50px;
    height: 50px;
    background-color: rgba($color: #333, $alpha: .5);
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
    display: none;
    border: 3px solid rgba($color: #aaa, $alpha: .5);
    border-radius: 50%;
    cursor: pointer;
  }
  #audBtn::before{
    left: 50%;
    top: 48%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    font-size: 1.8rem;
  }


