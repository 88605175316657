//== Colors
//

$c-highlight: #F65058;
$c-prehighlight: #ffada8;
$c-dark: #000000;
$c-light: #E5E5E5;
$c-gray: #707070;
$c-white: #ffffff;


//== Typography
//

// Font family
$ff: 'Helvetica',
sans-serif;

$font-family-sans-serif: $ff;
$font-family-base: $ff;

// Font Weight
$fw-regular: 500;
$fw-thin: 300;
$fw-bold: 700;



// Font Size
$fs-xl: 3.1rem;
$fs-large: 2.5rem;
$fs-base: 1.8rem;
$fs-small: 1.1rem;
$fs-tiny: 0.8rem;

$font-size-h1: $fs-xl;
$font-size-h2: $fs-large;
$font-size-h3: $fs-large;
$font-size-h4: $fs-base;
$font-size-h5: $fs-tiny;
$font-size-h6: $fs-tiny;

// Line height
$line-height-lg:              1.4;
$line-height-sm:              1.4;
$headings-line-height:        1.3;

$lh-headline: 1.4;
$lh-base: 1.6;

// Letter spacing
$ls-sm: 0.1rem;
$ls-md: 0.25rem;
$ls-lg: 0.5rem;


//== Transitions
//

// Timing
$t-x-short : 0.1s;
$t-short : 0.2s;
$t-medium : 0.3s;
$t-long : 0.6s;
$t-x-long : 0.9s;

// EASING

// Cubic
$ease-in-cubic : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-out-cubic : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-in-out-cubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
$ease-in-circ : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-out-circ : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-in-out-circ : cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
$ease-in-expo : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-out-expo : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-in-out-expo : cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
$ease-in-quad : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-out-quad : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-in-out-quad : cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
$ease-in-quart : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-out-quart : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-in-out-quart : cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
$ease-in-quint : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-out-quint : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-in-out-quint : cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
$ease-in-sine : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-out-sine : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-in-out-sine : cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
$ease-in-back : cubic-bezier(0.600, -0.280, 0.735, 0.045);
$ease-out-back : cubic-bezier(0.175, 0.885, 0.320, 1.275);
$ease-in-out-back : cubic-bezier(0.680, -0.550, 0.265, 1.550);

$ease-default : $ease-out-quad;



//== Shadows
//

$shadow-large: 0 2.8px 2.5px rgba(0, 0, 0, 0.042),
0 6.7px 6px rgba(0, 0, 0, 0.061),
0 12.5px 11.3px rgba(0, 0, 0, 0.075),
0 22.3px 20.1px rgba(0, 0, 0, 0.089),
0 41.8px 37.6px rgba(0, 0, 0, 0.108),
0 100px 90px rgba(0, 0, 0, 0.15);


$shadow-medium: 
0 8px 16px rgba(0, 0, 0, 0.6);

$shadow-medium-active: 
0 8px 16px rgba(0, 0, 0, 0.75);




//== Spacing
//

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((0: 0,
        1: ($spacer * 1),
        2: ($spacer * 2),
        3: ($spacer * 3),
        4: ($spacer * 4),
        5: ($spacer * 5),
        6: ($spacer * 6),
        7: ($spacer * 7),
        8: ($spacer * 8),
        9: ($spacer * 9),
        10: ($spacer * 10),
        11: ($spacer * 11),
        12: ($spacer * 12),
        13: ($spacer * 13),
        14: ($spacer * 14),
        15: ($spacer * 15),
        16: ($spacer * 16),
        17: ($spacer * 17),
        18: ($spacer * 18),
        19: ($spacer * 19),
        20: ($spacer * 20),
        21: ($spacer * 21),
        22: ($spacer * 22),
        23: ($spacer * 23),
        24: ($spacer * 24),
        30: ($spacer * 30),
        31: ($spacer * 31),
        32: ($spacer * 32),
        33: ($spacer * 33),
        34: ($spacer * 34),
        35: ($spacer * 35),
        36: ($spacer * 36)), $spacers);



//== Grid
//

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    33: 33%,
    50: 50%,
    66: 66%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// $grid-breakpoints: (xs: 0,
//     sm: 667px,
//     md: 768px,
//     lg: 992px,
//     xl: 1200px,
//     xxl: 1440px,
//     xxxl: 1680px,
//     xxxxl: 1920px,
//     xxxxxl: 2560px);

// $container-max-widths: (xs: 320px,
//     md: 667px,
//     lg: 768px,
//     xl: 990px,
//     xxl: 1080px,
//     xxxl: 1360px,
//     xxxxl: 1440px,
//     xxxxxl: 1680px,
//     xxxxxxl: 1920px);

// $grid-columns: 12;
// $grid-gutter-width: 30px;

// $enable-grid-classes: true;