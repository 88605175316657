.c {
    &--highlight {
        color: $c-highlight;
    }
    &--dark {
        color: $c-dark;
    }
    &--light {
        color: $c-light;
    }
    &--gray {
        color: $c-gray;
    }
    &--white {
        color: $c-white;
    }
}