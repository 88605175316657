/************ 
* OVERRIDES
*************/


html,
body {
  height: 100%;
  background: $c-white;
  word-wrap: break-word;
  /* or 
  -webkit-text-stroke: 0.45px;*/
}

.textFitAlignVertFlex .textFitAlignVert{
  width: 100%;
}

img {
  max-width: 100%;
}

a,
a:visited,
a:active {
  color: $c-dark;
}

a:hover {
  color: $c-highlight;
}




/* .container
{
  align-items: center;
  justify-content: center;
  display: flex;
} */
/* .cols{
   align-items: center;
  justify-content: center;
  display: grid; 
} */
