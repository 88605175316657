html, body {
    font-family: 'Lato' !important;
}
b, strong {
    font-weight: 900;
}
p {
    font-weight: 400 !important;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
p:empty {
    display: none;
 }
h3, h4, p {
    font-style: italic;
}
h1, .h1,
h2, .h2{
    text-transform: uppercase;
    font-weight: 900;
    text-align: left !important;
}
h1.h1-section {
    font-size: 1rem;
    width: 100%;
    padding-left: 10%;
    margin: 0;
    position: relative;
    z-index: 99;
    /* opacity: 0; */

    .header-container {
        padding: 0.7rem 5% 0.2rem 0;
    }
}
h2, .h2 {
    font-size: 0.85rem;
    line-height: normal;
    padding-bottom: 20px;
}

#headline-line {
    margin-bottom: 1.5rem;
    z-index: 100;
    position: relative;
}
hr.thin{
    border-bottom: 5px solid $c-dark;
    margin: 0.3rem 0 0.4rem;
}
hr.thick{
    border-bottom: 5px solid $c-dark;
}
hr.thickest{
    border-bottom: 8px solid $c-dark;
    padding:0;
    margin: 0 0 1rem;
}

figure {
    margin: 0;
}

.reveal .slides{
    height: 90%;
    margin: 0;
}
.reveal.center .slides section:not(.stack){
    justify-content: flex-end;
    height: auto;
    padding: 0;

    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 90vw;
        margin: 0;
    
        .row {
            max-width: 100%;
            margin: 0;
            align-content: space-between;
            flex-flow: wrap;

            &.type-indications-imageonly, &.valign-center {
                align-items: center;
                align-content: initial;
                height: 85vh;
                /* width: 90%; */
                width: 100%;

                img {
                    max-height: none;
                    padding-right: 2rem;
                }
            }
        }
    }
}

.relative {
    position: relative;
    overflow: visible;
}

.inline-b {
    display: inline-block;
}

img.glow {
    background-color: #ffffffe3;
    border-radius: 20px;
    box-shadow: 0px 0px 90px 70px #fffffff0;
    position: relative;
} 
.glow,
.glow-small,
.glow-tiny{
    position: relative; 
    z-index: 10;   
}
.glow::before,
.glow-small::before,
.glow-tiny::before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110%;
    height: 100%;   
    z-index: -1;
    content: "";
    background-image: url('/assets/images/white-glower.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.glow::before{ 
    opacity: 0.92;
    filter: blur(26px); 
}
.glow-small::before {
    opacity: 0.98;
    filter: blur(14px); 
}
.glow-tiny::before {
    opacity: 1;
    filter: blur(8px);
    width: 105%;
    height: 101%; 
}

.header-container{
    &.glow::before{
        top: 64%;
        height: 110%;
    }
}

img.not-rounded {
    border-radius: 0px;
}




.footerevents, .footerinfo {
    font-size: 0.35rem;
    position: absolute;
    bottom: 10px;
    max-height: 150px;
    width: 90vw;
    right: 0;
    padding-right: 1rem;
    padding-bottom: 0.4rem;
    text-align: left;
    align-content: flex-start;

    img {
        max-height: 90px;
    }
    p {
        margin-bottom: 0;
    }
}


/***
MODULES 
****/

.latestinfo {
    font-size: 0.75rem;
    line-height: 1.05;
    margin-bottom: 2rem;
    width: 100%;

    &:last-of-type {
        margin-bottom: 0;
    }
    h2, .h2 {
        border-bottom: 6px solid $c-dark;
    }
    h2, p {
        padding-right: 1rem;
    }
    .p-de {
        margin-bottom: 0.5rem;

        p {
            margin-bottom: 0;
        }
    }
    .p-en {
        font-size: 0.65rem;
    }
    p:last-of-type{
        margin-bottom: 0;
    }
}

.eventsmall {
    font-size: 0.6rem;
    line-height: 1.05;
    margin-bottom: 80px;
    width: 100%;
    padding-bottom: 0.25rem;

    &:last-of-type {
        margin-bottom: 0;
    }
    
    h2, .h2 {
        font-size: 0.65rem;
    }

    .row {
        padding-right: 1rem;

        .col {
            padding: 0;
        }
    }

    img {
        max-height: 200px;
        width: auto;
    }

    .text-element p{
        margin-bottom: 5px;
    }
}

.eventmonth {
    font-size: 0.6rem;
    line-height: 1.05;
    margin-bottom: 2rem;
    width: 100%;
    padding-bottom: 0.25rem;
    position: relative;

    &.glow::before {
        height: 107%;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
    
    h2, .h2 {
        font-size: 1rem;
    }

    h2, .h2, p {
        padding-right: 1rem;
    }

    .entry{
        margin-bottom: 1rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        .time p{
            font-weight: 900 !important;
        }
    }

    .text-element p{
        margin-bottom: 5px;
    }
}

.exhibition-entry,
.kv-entry{
    font-size: 0.6rem;
    line-height: 1.05;
    margin-bottom: 80px;
    width: 100%;
    padding-bottom: 0.25rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    .announcement {
        font-size: 0.9rem;
        font-weight: 900 !important;
        position: relative;
        z-index: 10;
        margin-bottom: 0.75rem;
    }

    .swiper-container {
        margin-bottom: 0.8rem;
        /* padding-right: 1.8rem; */
        height: 61vh;
        overflow: visible;

        .swiper-slide {
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            justify-content: left;

            figure {
                max-height: 100%;
                min-width: 100%;
                object-fit: contain;
                vertical-align: bottom;

                img {
                    padding-right: 2rem;
                    object-fit: contain;

                    &.portrait {
                        padding-right: 3.1rem;
                    }
                }

                &.portrait {
                    height: 100%;
                    min-width: initial;
                    width: auto;

                    img {
                        width: auto;
                        height: 97%;
                    }
                }
            }
        }
    }
    
    p.copyright {
        font-size: 0.3rem;/* 
        text-align: right;
        padding-right: 0; */
        margin-top: 0.25rem;
        width: 100%;
        clear: both;
        position: relative;
        z-index: 10;
        height: 3%;
    }

    .place{
        padding-right: 1rem;
        position: relative;

        p {
            font-weight: 900 !important;
            width: 70%;
        }

        .img-places {
            width: 2rem;
            background:transparent;
            min-height: 0.75rem;
            font-size: 17px;
            position: absolute;
            right: 2.8rem;
            bottom: 0;
            padding: 0;

            &.kv-entry {
                right: 1.8rem;
            }
        }
        
    }

    p {
        margin-bottom: 0.2rem;
    }

    h2, .h2 {
        font-size: 0.75rem;
        text-transform: initial;
        margin-bottom: 0;
    }

    h2, .h2, p {
        padding-right: 1rem;
    }

    hr {
        position: relative;
        z-index: 99;
    }
}

.kv-entry {
    .swiper-container {
        height: 58vh;

        .swiper-slide {
            figure {
                img {
                    &.portrait {
                        padding-right: 3.9rem;
                    }
                }
            }
        } 
    } 
}

.specials-entry {
    font-size: 0.55rem;
    line-height: 1.05;
    width: 100%;
    padding-bottom: 0rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0.2rem;
    }

    h2, .h2 {
        font-size: 0.75rem;
        text-transform: initial;
        margin-bottom: 0;
        padding-right: 1.8rem;
    }

    hr {
        position: relative;
        z-index: 10;
    }

    img {
        &.banner {
            max-width: 100%;
            margin-bottom: 1.3rem;
            object-fit: cover;
            max-height: 350px;
            padding-right: 1.8rem;
            z-index: 0;
        }
    }

    .time {
        font-weight: 900 !important;
    }

    .description {
        padding-right: 1rem
    }

    .swiper-container {
        overflow: visible;
    }

    .row.time-entry:not(:last-of-type) {
        margin-bottom: 0.8rem !important; 
    }

    .row.time-entry:last-of-type {
        p {
            margin-bottom: 0;
        }
    }
}

.eventmonth, .eventsmall, .exhibition-entry, .kv-entry {
    hr {
        margin: 0 0 0.35rem;
    }
    h2, .h2 {
        padding-bottom: 0;
        margin-bottom: 0.2rem;
    }
}

.type-information {
    font-size: 0.75rem;
    line-height: 1.05;
    margin-bottom: 80px;
    width: 100%;

    .row.content-section:not(:last-of-type) {
        margin-bottom: 2.6rem !important;
    }   

    .row {
        width: 100%;
    }

    .cols {
        width: 100%;
        padding: 0;

        &.left{
            padding-right: 0.8rem;
        }

        .lang {
            display: inline-block;            
        }
        .lang, .static {
            margin-bottom: 0.3rem;
        }

        h2, .h2 {
            margin-bottom: 0.2rem;
            padding: 0;
            font-weight: 900 !important;
            font-style: normal;
        }

        .lang-el {
            font-size: 0.5rem;
            opacity: 0;
            line-height: 0.6rem;
        }

        &.pricecomment {
            padding-right: 1rem;
            .lang {
                width: 100%;
                max-width: none;
            }
        }
        &.col-en {
                font-size: 0.6rem;
                     
        }
    }

    hr {
        display: block;
        width: 100%;
        margin-bottom: 0.35rem;

        &.thick, &.thin {
            margin-top: 5px;
        }
        
    }
    .swiper-container {
        overflow: visible;
    }
    .swiper-slide {
        width: 100% !important;
        
        p {
            margin-bottom: 0.25rem;
            word-wrap: normal;
            hyphens: none;

            &.h2 {
                font-size: 0.5rem;
                line-height: 0.6rem;
            }
        }
        .fine-print {
            p {
                font-size: 0.4rem;
                line-height: normal;
                padding-right: 1rem;
            }            
        }
        .headline-icon {
            display: inline-block;
            vertical-align: bottom;
        }
        h2.headline-icon {
            width: 85%;
            padding-right: 5%;
        }
        img.headline-icon {
            object-fit: contain;
            position: absolute;
            max-height: 70px;
            top: -20px;
            left: 0;
            width: auto;
        }

        .row:last-of-type {
            hr.slide:last-child{
                display: none;
            }
        }
    }
}

.customBody{
    &::after, .vid-bg-container {
        transition: opacity 0.5s;
    }  
    &.bg-activated {
        &::after {
            opacity: 1;
        }

        .vid-bg-container{
            opacity: 1 !important;
        }
    }
}

.swiper-slide {
    opacity: 0;
    transition:  opacity .3s ease-in-out !important;

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
        opacity: 1;
    }
}


