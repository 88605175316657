//
// Lato Regular
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-regular.eot');
  src: url('#{$lato-font-path}/lato-regular.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-regular.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-regular.woff') format('woff'),
       url('#{$lato-font-path}/lato-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
