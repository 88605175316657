.slides section,
.header-container,
#headline-line,
.custom-animate {
    transition: opacity 3s, filter 2s !important;
    filter: blur(0px);
    opacity: 1;
}

.slides section.fadeOutSection {   
    opacity: 0 !important;
}

.header-container.fadeOutSection,
#headline-line.fadeOutSection,
.fadeOutSection {
    opacity: 0;
}

.header-container.blurred,
.slides section.blurred,
#headline-line.blurred,
.blurred {
    filter: blur(25px);
}
.header-container {
    height:auto;
}
.no-display{
    height:0;
    max-height: 1px;
    padding: 0 !important;
}