//
// Typography
// --------------------------------------------------

html{ 
  
  @include media-breakpoint-up (sm) {
    font-size: 1.35rem;
  }
  @include media-breakpoint-up (md) {
    font-size: 1.65rem;
  }  
  @include media-breakpoint-up (xl) {
    font-size: 2rem;
  }
  @include media-breakpoint-up (xxl) {
    font-size: 2.8rem;
  }  
  font-size: 1rem;  
}
html.portrait{ 
  font-size: 3.3rem;  
}

html, body {
  font-kerning: normal;
  -webkit-font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  font-family: $ff;
  color: $c-dark;
}


h1, h2, h3, h4, h5, h6, p{
  text-shadow: 1px 1px 2px rgba($color: #222222, $alpha: .2) !important;
}

h1, h2, h3, h4, h5, h6{
  line-height: 1.1;
}

h1, h2 {
  margin-bottom: 30px;
}

h3, h4 {
  margin-bottom: 20px;
}

.force-lowercase {
  text-transform:initial;
}

.align-global{
  text-align: inherit;
}
.align-left{
  text-align: left;
}
.align-center{
  text-align: center;
}
.align-right{
  text-align: right;
}
.align-justified{
  text-align:justify;
}

